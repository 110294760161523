import { Fragment } from "react";
import Nav from "./nav";
import Footer from "./footer";

export default function Terms() {
	return (
		<Fragment>
			<Nav />
			<div className="text-secondary flex items-center flex-col h-[30vh] pt-[9vw] max-[530px]:pt-[10vw] max-[530px]:h-[10rem] max-[767px]:h-[20rem] max-[992px]:h-[18rem] max-[992px]:pt-[18vw] max-[1199px]:h-[14rem]">
				<h1 className="text-4xl font-bold text-secondary text-center mb-2 max-[530px]:text-3xl max-[530px]:pt-[3rem] max-[767px]:text-5xl max-[767px]:pt-[3rem]">
					Terms and Conditions
				</h1>
			</div>
			<div className="h-[100vh] px-[8vw] py-[4vw] text-justify">
				<ul>
  <li><strong>Acceptance of Terms:</strong> By using this website, you agree to comply with these terms and applicable laws.</li>
  <li><strong>Use of Website:</strong> You agree to use the website for lawful purposes only.</li>
  <li><strong>Intellectual Property:</strong> All content is the property of Krise Online and cannot be used without permission.</li>
  <li><strong>Product Information:</strong> Prices, availability, and descriptions are subject to change without notice.</li>
  <li><strong>Order Process:</strong> Orders are subject to acceptance and availability. We reserve the right to cancel incorrect orders.</li>
  <li><strong>Payment Terms:</strong> Payments must be made at the time of purchase. Accepted methods may change.</li>
  <li><strong>Shipping & Delivery:</strong> Shipping times and costs vary. We are not liable for delivery delays.</li>
  <li><strong>Returns & Refunds:</strong> Items can be returned within 30 days in their original condition for a refund.</li>
  <li><strong>Limitation of Liability:</strong> We are not liable for any damages from website use or product issues.</li>
  <li><strong>Amendments:</strong> Terms may be updated at any time. Changes take effect immediately upon posting.</li>
</ul>

			</div>
			<Footer />
		</Fragment>
	);
}
